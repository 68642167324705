import React, { useState } from "react";
import { Link } from 'react-router-dom';

const images = [
  {
    id: 1,
    name: "Conect Plus",
    adjuvantes: true,
    imgUrl: require('../../assets/images/products/adjuvantes/conect-plus.webp'),
    text: ['Reduz e Estabiliza o PH', 'Efeito Tamponante', 'Melhor Uniformidade de Gotas', 'Ação Anti Deriva', 'Sequestrante de Cátions', 'Efeito Anti Espuma']
  },
  {
    id: 2,
    name: "Conect Neutro",
    adjuvantes: true,
    imgUrl: require('../../assets/images/products/adjuvantes/conect-neutro.webp'),
    text: ['Melhor Uniformidade de Gotas','Ação Anti Deriva','Sequestrante de Cátions','Efeito Anti Espuma','Não Interfere no PH']
  },
  {
      id: 3,
      name: "Conect-D Limoneno",
      adjuvantes: true,
      imgUrl: require('../../assets/images/products/adjuvantes/conect-d_limoneno.webp'),
      text: ['Rápida Absorção','Melhor Uniformidade de Gotas','Ação Anti Deriva','Efeito Translaminar','Não Interfere no PH']
  },
  {
      id: 4,
      name: "Conect Top Oil",
      adjuvantes: true,
      imgUrl: require('../../assets/images/products/adjuvantes/conect-top_oil.webp'),
      text: ['Óleo emulsionável','Espalhante adesivo','Diminuição da tensão superficial','Utilização com herbicida, inseticida e fungicida','Possui agentes espessantes e emulsificantes','Melhor uniformidade de gotas','Ação anti deriva','Não interfere no PH']
  },
  {
      id: 5,
      name: "Conect N-32",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-n32.webp'),
      text: ['Fertilizante foliar a base de nitrogênio, em alta concentração sendo disponibilizado nas formas nítrica, amidica e amoniacal, tendo a especificidade de liberação gradativa, proporcionando um maior desenvolvimento vegetativo.']
  },
  {
      id: 6,
      name: "Conect Boro 10",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-boro10.webp'),
      text: ['Fertilizante mineral simples proveniente de fontes totalmente solúveis em agua, para uso em aplicações foliares durante todo o ciclo dos cultivos.','Proporcionando síntese de ácidos nucleicos e de fito hormônios, divisão celular lignificação e formação de paredes celulares.']
  },
  {
      id: 7,
      name: "Conect Molibdênio 15",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-molibdenio15.webp'),
      text: ['Fertilizante foliar 100% solúvel em água, nutriente este necessário para a síntese e ativação do metabolismo do nitrogênio.']
  },
  {
      id: 8,
      name: "Conect CoMo 10-1",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-como10_1.webp'),
      text: ['Fertilizante líquido a base de cobalto e molibdênio nocivo as bactérias fixadoras de nitrogênio, suas partículas são extremamente pequenas e uniformes ampliando seu contato com a superfície do solo e com sistema radicular aumentando a solubilização e absorção dos nutrientes pelas plantas.']
  },
  {
      id: 9,
      name: "Conect Mn10",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-mn10.webp'),
      text: ['Produto a base de sulfato de manganês contendo também em sua formulação enxofre , sendo que o manganês esta envolvido com o processo fotossintético na formação da biossíntese de clorofila.']
  },
  {
      id: 10,
      name: "Conect Calcio 14",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-calcio-14.webp'),
      text: ['Fertilizante líquido a base de cálcio, nutriente que desempenha um papel estrutural importante a nível de formação e rigidez da parede celular, por sua vez sendo essencial nas fases de formação e desenvolvimento da cultura.']
  },
  {
      id: 11,
      name: "Conect P 30",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-p30.webp'),
      text: ['Fertilizante foliar, contendo em sua formulação o elemento Fósforo (P2O5), sendo este essencial para a produção de ATP (Energia) para desenvolvimento da cultura.']
  },
  {
      id: 12,
      name: "Conect MAG 8,5",
      corretores_de_carencia: true,
      imgUrl: require('../../assets/images/products/corretores de carencia/conect-mag85.webp'),
      text: ['Fertilizante foliar mineral misto proveniente de fontes totalmente solúveis em agua , contendo em sua formulação magnésio , sendo um nutriente que proporciona reações enzimáticas no processo de fotossíntese.']
  },
  {
    id: 13,
    name: "Conect Raiz",
    especiais: true,
    imgUrl: require('../../assets/images/products/especiais/conect-raiz.webp'),
    text: ['Fertilizante orgâno mineral classe “A”, a base de extrato de algas (Ascophyllum nodosum), substâncias húmicas, aminoácidos e carbono orgânico, atua como um promotor hormonal, auxiliando na germinação, acelera o desenvolvimento radicular e arranque inicial da cultura.']
  },
  {
    id: 14,
    name: "Conect Produce",
    especiais: true,
    imgUrl: require('../../assets/images/products/especiais/conect-produce.webp'),
    text: ['É um complexo nutricional de macro e micronutrientes e possui em sua composição uma cadeia de aminoácidos e alto teor de carbono orgânico, sua formulação balanceada acelera o metabolismo da cultura proporcionando uma rápida recuperação causada por estresses bióticos ou abióticos, aumenta a absorção, translocação e assimilação dos nutrientes na planta, proporcionando assim maior desenvolvimento vegetativo e produtivo.']
  },
  {
    id: 15,
    name: "Conect Force K",
    especiais: true,
    imgUrl: require('../../assets/images/products/especiais/conect-force-k.webp'),
    text: ['Produto a base de potássio (K2O) elaborado para proporcionar o aumento de peso especifico, a concentração de sólidos solúveis BRIX ou ATR e o teor de carboidratos dos grãos e/ou frutos.']
  },
  {
    id: 16,
    name: "Conect Maximus",
    especiais: true,
    imgUrl: require('../../assets/images/products/especiais/conect-maximus.webp'),
    text: ['Complexo elaborado a base de macro e micronutrientes essenciais, que garantem o maior equilíbrio nutricional e desenvolvimento da cultura. ']
  },
  {
    id: 17,
    name: "Conect Protector",
    especiais: true,
    imgUrl: require('../../assets/images/products/especiais/conect-protector.webp'),
    text: ['Produto desenvolvido com uma formulação equilibrada e balanceada, contendo em sua garantia os elementos: Cobre (Cu), Zinco (Zn), Enxofre (S) e Nitrogênio (N). Visando melhorar a resistência das plantas (Pragas e Doenças), formando uma camada protetora nas folhas e frutos.']
  },
];

const FILTER_DEFS = {
    ADJUVANTES: image => image.adjuvantes,
    CORRETORES_DE_CARENCIA: image => image.corretores_de_carencia,
    ESPECIAIS: image => image.especiais,
    DEFENSIVOS: image => image.defensivos,
    NONE: image => image
};

const filter_keys = ["ADJUVANTES", "CORRETORES_DE_CARENCIA", "ESPECIAIS", "DEFENSIVOS", "NONE"];

var eventHandler = {
  name: "eventHandler", 
  handleEvent: function (event) {
    console.log(eventHandler.name);
  }  
}

function removeActive(item){
  let element2 = document.getElementById(item);
  if(element2 != null){
    element2.classList.remove("active");
  }
}

function setId(id){
  console.log(id);
  let docId = document.getElementById(id);
  if(docId != null){
    docId.classList.add("active");

    for(var i = 0; i < filter_keys.length; i++){
      if(filter_keys[i] != id){
        removeActive(filter_keys[i]);
      }
    }
  }
}


const PortfolioFilter1 = props => {
  const [state, setState] = useState({
      list: images,
      filterKey: "NONE",
  });
  if(state.filterKey == null){
    setId("NONE");
  }
  else{
    setId(state.filterKey);
  }


    const { list, filterKey } = state;
    const filteredList = list.filter(FILTER_DEFS[filterKey]);
    return (
        <>
            <section class="gallery-section">
                <div class="sortable-masonry">
                    <div class="auto-container">
                        <div class="row m-0 justify-content-md-between align-items-center">
                            <div class="sec-title">
                                <div class="sub-title">Produtos</div>
                                <h2>Nossos Produtos</h2>
                            </div>
                            {/* <!--Filter--> */}
                            <div class="filters text-center wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                <ul class="filter-tabs filter-btns">
                                    <li id="NONE" class="active filter" onClick={() => setState({ ...state, filterKey: "NONE" })}><span>Todos</span></li>
                                    <li id="ADJUVANTES" class="filter" onClick={() => setState({ ...state, filterKey: "ADJUVANTES" })}><span>Adjuvantes</span></li>
                                    <li id="CORRETORES_DE_CARENCIA" class="filter" onClick={() => setState({ ...state, filterKey: "CORRETORES_DE_CARENCIA" })}><span>Corretores de Carência</span></li>
                                    <li id="ESPECIAIS" class="filter" onClick={() => setState({ ...state, filterKey: "ESPECIAIS" })}><span>Especiais</span></li>
                                    <li id="DEFENSIVOS" class="filter" onClick={() => setState({ ...state, filterKey: "DEFENSIVOS" })}><span>Defensivos</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="auto-container">
                        <div class="items-container row">

                            {filteredList.map(image => (
                                <div class="col-lg-6 col-md-4 gallery-block-one" key={image.id}>
                                    <div class="inner-box">
                                        <div class="image">
                                            <img src={image.imgUrl} alt="" />
                                        </div>
                                        <div class="caption-title">
                                            <h5>{image.name}</h5>
                                            {image.text.map(t => (
                                                <h6>{t}</h6>
                                            ))}
                                            {/* <div class="view-project"><Link data-fancybox="example gallery" to="assets/images/products/gallery-10.jpg" class="zoom-btn"><span>+</span></Link></div> */}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            {/* {document.getElementById("image_text").value.replace(/\n/g, '<br>') } */}

                        </div>
                    </div>
                </div>
            </section>
















        </>
    );
};

export default PortfolioFilter1;
