import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../layout/header'
import Footer from '../layout/footer'
import GoogleMaps from "simple-react-google-maps";

const aboutbg = require('./../../assets/images/background/image-11.jpg');




class Contact extends Component {


    render() {
        return (
            <>
                <Header />
                

                {/* <!--Search Popup--> */}
                <div id="search-popup" class="search-popup">
                    <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
                    <div class="popup-inner">
                        <div class="overlay-layer"></div>
                        <div class="search-form">
                            <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                                <div class="form-group">
                                    <fieldset>
                                        <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                        <input type="submit" value="Search Now!" class="theme-btn"/>
                                    </fieldset>
                                </div>
                            </form>
                            <br/>
                            <h3>Recent Search Keywords</h3>
                            <ul class="recent-searches">
                                <li><Link to={'/#'}>Finance</Link></li>
                                <li><Link to={'/#'}>Idea</Link></li>
                                <li><Link to={'/#'}>Service</Link></li>
                                <li><Link to={'/#'}>Growth</Link></li>
                                <li><Link to={'/#'}>Plan</Link></li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- Page Banner Section --> */}
                <section class="page-banner">
                    <div className="page-banner-bg" style={{ backgroundImage: "url(" + aboutbg + ")" }}></div>
                    <div class="bottom-rotten-curve alternate"></div>

                    <div class="auto-container">
                        <h1>Fale Conosco</h1>
                        <ul class="bread-crumb clearfix">
                            <li><Link to={'/#'}>Home</Link></li>
                            <li class="active">Contact</li>
                        </ul>
                    </div>

                </section>

                {/* <!-- Map Section --> */}
                <section class="map-section">
                    <div class="map-column">
                        <GoogleMaps
                            apiKey={"AIzaSyCK3I31_cWq_ioDT2wb2VvZ00tVRO6B2LM"}
                            style={{ height: "800px", width: "100%" }}
                            zoom={18}
                            center={{ lat: -20.444139204785834, lng: -54.61798352439648 }}
                            markers={{ lat: -20.444139204785834, lng: -54.61798352439648 }} //optional
                            title={'Campos Representações'}
                            //-20.444139204785834, -54.61798352439648
                        />
                    </div>
                </section>



                {/* <!-- Contact Section Two --> */}
                <section id="contact" class="contact-section-two">
                    <div class="auto-container">
                        <div class="contact-info-area">
                            <div class="contact-info">
                                <div class="row">
                                    <div class="info-column col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-email-6"></span></div>
                                            <h3>E-mail</h3>
                                            <ul>
                                                <li><a href='mailto:contato@camposrepresentacoes.agr.br'>contato@camposrepresentacoes.agr.br</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info-column col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-location"></span></div>
                                            <h3>Endereço</h3>
                                            <ul>
                                                <li>Rua Dr. Dolor Ferreira de Andrade, 135, Sala 06 - Centro<br />Campo Grande - MS</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="info-column col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                                        <div class="icon-box">
                                            <div class="icon"><span class="flaticon-call-1"></span></div>
                                            <h3>Telefone/Whatsapp</h3>
                                            <ul>
                                              <li><a href="https://wa.me/message/IOO5L5U267JJG1">+55 (67) 3204-2642</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="contact-form-area">
                            <div class="sec-title text-center">
                                <div class="sub-title">Contato</div>
                                <h2>Fale conosco</h2>
                            </div>
                            {/* <!-- Contact Form--> */}
                            <div class="contact-form">
                                <form method="post" action="http://formmail.kinghost.net/formmail.cgi" id="contact-form">
                                {/* http://formmail.kinghost.net/formmail.cgi */}
                                <input type="hidden" name="recipient" value="camposrepresentacoes@camposrepresentacoes.agr.br"/>
                                <input type="hidden" name="redirect" value="#"/>
                                {/* <input type="hidden" name="return_link_title" value="E-mail enviado com sucesso!"/> */}
                                <input type="hidden" name="subject" value="[CONTATO_PELO_SITE]"/>
                                <input type="hidden" name="email" value="camposrepresentacoes@camposrepresentacoes.agr.br"/>

                                    <div class="row clearfix">
                                        <div class="col-md-4 form-group">
                                            <label for="name">Nome</label>
                                            <input type="text" name="name" id="name" placeholder="Preencha seu nome aqui..." required="" />
                                            <i class="fas fa-user"></i>
                                        </div>

                                        <div class="col-md-4 form-group">
                                            <label for="email">E-mail</label>
                                            <input type="email" name="email" id="email" placeholder="Preencha seu e-mail aqui..." required="" />
                                            <i class="fas fa-envelope"></i>
                                        </div>

                                        <div class="col-md-4 form-group">
                                            <label for="tel">Telefone/Whatsapp</label>
                                            <input type="tel" name="tel" id="tel" placeholder="Preencha seu telefone com DDD..." required="" />
                                            <i class="fas fa-phone"></i>
                                        </div>

                                        <div class="col-md-12 form-group">
                                            <label for="message">Mensagem</label>
                                            <textarea name="message" id="message" placeholder="Digite sua mensagem..."></textarea>
                                            <i class="fas fa-edit"></i>
                                        </div>

                                        <div class="col-md-12 form-group">
                                          <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title">Enviar</span></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>






                <Footer />
            </>
        )
    }
}
export default Contact;