import React, { Component } from 'react';
import { Link } from 'react-router-dom';



class Menu extends Component {


    render() {
        return (
            <>
                <div class="collapse navbar-collapse show clearfix" id="navbarSupportedContent">
                    <ul class="navigation clearfix">
                        <li><a href='/'>Home</a></li>
                        <li><a href='/#about-us'>Sobre nós</a></li>
                        {/* <li><a herf='/#'>Serviços</a>
                            <ul>
                                <li><Link to={'/services-1'}>Services One</Link></li>
                                <li><Link to={'/services-2'}>Services Two</Link></li>
                                <li><Link to={'/services-details'}>Services Details</Link></li>
                            </ul>
                        </li> */}
                        <li><Link to={'/produtos'}>Produtos</Link>
                            {/* <ul>
                                {<li}><Link to={'/portfolio-1'}>Portfolio One</Link></li>
                                <li><Link to={'/portfolio-2'}>Portfolio Two</Link></li>
                                <li><Link to={'/portfolio-details'}>Portfolio Details</Link></li>
                            </ul> */}
                        </li>
                        {/* <li><a href='/#'>Blog</a>
                            <ul>
                                <li><Link to={'/blog-grid'}>Blog Grid</Link></li>
                                <li><Link to={'/blog-standard'}>Blog Standard</Link></li>
                                <li><Link to={'/blog-details'}>Blog Details</Link></li>
                            </ul>
                        </li> */}
                        {/* <li class="dropdown"><Link to={'/#'}>Pages</Link>
                            <ul>
                                <li><Link to={'/careers'}>Careers</Link></li>
                                <li><Link to={'/team'}>Team</Link></li>
                                <li><Link to={'/team-details'}>Team Details</Link></li>

                                <li class="dropdown"><Link to={'/#'}>Shop</Link>
                                    <ul>
                                        <li><Link to={'/shop'}>Products</Link></li>
                                        <li><Link to={'/product-details'}>Product Details</Link></li>
                                        <li><Link to={'/shopping-cart'}>Shopping Cart</Link></li>
                                        <li><Link to={'/checkout'}>Checkout</Link></li>
                                    </ul>
                                </li>
                                <li><Link to={'/faq'}>Faq's</Link></li>
                                <li><Link to={'/404'}>404</Link></li>
                            </ul>
                        </li> */}

                        <li><a href='/#contact'>Contato</a></li>
                    </ul>
                </div>

            </>
        )
    }
}
export default Menu;