import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from './menu'



class Header3 extends Component {

  state = {
    scrolled: false
  };



  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = event => {
    if (window.scrollY > 100) {
      this.setState({
        scrolled: true
      });
    } else {
      this.setState({
        scrolled: false
      });
    }
  };

  componentDidMount() {

    var sidenavbtn = document.querySelector('.sidemenu-nav-toggler');
    var aaa = document.querySelector('.hidden-sidebar');
    var closesidebarbtn = document.querySelector('.hidden-sidebar-close');

    function toggleFunc() {
      return aaa.classList.toggle("close-sidebar");
    }

    function closesidebar() {
      return aaa.classList.remove("close-sidebar");
    }



    // sidenavbtn.addEventListener('click', toggleFunc);
    // closesidebarbtn.addEventListener('click', closesidebar);


    var mobilebtn = document.querySelector('.mobile-nav-toggler');
    var closeBtn = document.querySelector('.close-btn');
    var body = document.getElementsByTagName('body')[0];

    function addFunc() {
      return body.classList.add("mobile-menu-visible");
    }

    function closeFunc() {
      return body.classList.remove("mobile-menu-visible");
    }

    mobilebtn.addEventListener('click', addFunc);
    closeBtn.addEventListener('click', closeFunc);

    window.addEventListener("scroll", this.handleScroll);
  }

  render() {
    const {
      scrolled
    } = this.state;
    return ( 
      <>
        <header class={scrolled ? "main-header header-style-three fixed-header" : "main-header header-style-three" }>

          {/*
          <!-- Header Upper --> */}
          <div class="header-upper">
            <div class="auto-container">
              <div class="inner-container">
                {/*
                <!--Nav Box--> */}
                <div class="nav-outer clearfix">
                  {/*
                  <!--Logo--> */}
                  <div class="logo-box">
                    <div class="logo">
                      <Link to={'/'}> <img src={require('../../assets/images/sticker_cr_logo.png')} alt="" />
                      </Link>
                    </div>
                  </div>
                  {/*
                  <!--Sidemenu Navigation Toggler--> */}
                  {/* <div class="sidemenu-nav-toggler"><span class="icon fal fa-bars"></span></div> */}
                  {/*
                  <!--Mobile Navigation Toggler--> */}
                  <div class="mobile-nav-toggler"><span class="icon fal fa-bars"></span></div>

                  <div class="widget-content">
                    <ul class="social-links clearfix">
                      <li><a href='https://www.linkedin.com/company/camposrepresentacoes-agr' target="_blank" rel="noopener noreferrer"><span
                            class="fab fa-linkedin"></span></a></li>
                      <li><a href='https://wa.me/message/IOO5L5U267JJG1' target="_blank" rel="noopener noreferrer"><span class="fab fa-whatsapp"></span></a></li>
                      <li><a href='https://www.instagram.com/camposrepresentacoes.agr/' target="_blank" rel="noopener noreferrer"><span
                            class="fab fa-instagram"></span></a></li>
                      <li><a href='https://www.facebook.com/camposrepresentacoes.agr' target="_blank" rel="noopener noreferrer"><span
                            class="fab fa-facebook-f"></span></a></li>
                    </ul>
                  </div>

                  
                  {/*
                  <!-- Main Menu --> */}
                  <nav class="main-menu navbar-expand-md navbar-light">
                    <Menu />
                  </nav>
                  {/*
                  <!-- Main Menu End--> */}



                  {/*
                  <!-- Header Right Content --> */}
                  <div class="header-right-content clearfix">
                    <div class="contact-info">
                      <div class="icon-box"><i class="flaticon-call-1"></i></div>
                      <div class="text">WhatsApp</div>
                      <h4><a href="https://wa.me/message/IOO5L5U267JJG1" target="_blank" rel="noopener noreferrer">+55 (67) 3204-2642</a></h4>
                    </div>
                    {/*
                    <!-- Link Btn--> */}
                    {/* <div class="link-btn">
                      <Link to={'/#'} class="theme-btn btn-style-one"><span class="btn-title">Get A Quote</span></Link>
                    </div> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/*
          <!--End Header Upper--> */}

          {/*
          <!-- Sticky Header  --> */}
          <div class={scrolled ? "sticky-header animated slideInDown" : "sticky-header" }>
            <div class="auto-container clearfix">
              {/*
              <!--Logo--> */}
              <div class="logo pull-left">
                <a href='/' title=""><img src={require('../../assets/images/sticker_cr_logo.png')} alt="" title="" />
                </a>
              </div>
              {/*
              <!--Right Col--> */}
              <div class="pull-right">
                {/*
                <!-- Main Menu --> */}
                <nav class="main-menu clearfix">
                  <Menu />
                </nav>
                {/*
                <!-- Main Menu End--> */}
              </div>
            </div>
          </div>
          {/*
          <!-- End Sticky Menu --> */}

          {/*
          <!-- Mobile Menu  --> */}
          <div class="mobile-menu">
            <div class="menu-backdrop"></div>
            <div class="close-btn"><span class="icon flaticon-cancel"></span></div>

            <nav class="menu-box">
              <div class="nav-logo">
                <Link to={'/'}> <img src={require('../../assets/images/cr_logo.png')} alt="" title="" />
                </Link>
              </div>
              <div class="menu-outer">
                <Menu />
              </div>
              {/*
              <!--Social Links--> */}
              <div class="social-links">
                <ul class="clearfix">
                  <li><a href='https://www.linkedin.com/company/camposrepresentacoes-agr' target="_blank" rel="noopener noreferrer"><span class="fab fa-linkedin"></span></a></li>
                  <li><a href='https://wa.me/message/IOO5L5U267JJG1' target="_blank" rel="noopener noreferrer"><span class="fab fa-whatsapp"></span></a></li>
                  <li><a href='https://www.instagram.com/camposrepresentacoes.agr/' target="_blank" rel="noopener noreferrer"><span class="fab fa-instagram"></span></a></li>
                  <li><a href='https://www.facebook.com/camposrepresentacoes.agr' target="_blank" rel="noopener noreferrer"><span class="fab fa-facebook-f"></span></a></li>
                </ul>
              </div>
            </nav>
          </div>
          {/*
          <!-- End Mobile Menu --> */}

          <div class="nav-overlay remove">
          </div>
        </header>
        {/*
        <!-- End Main Header --> */}

        </>
        )
    }
}
export default Header3;
