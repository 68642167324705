import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Nav, Tab, } from 'react-bootstrap';
import Header from '../layout/header'
import HomeSlider from '../element/home-slider';
import Footer from '../layout/footer';
import Testimonial2 from '../element/testimonial2';
import CaseStudy from '../element/case-study';
import Featureslide from '../element/feature-slider';
import GoogleMaps from "simple-react-google-maps";
import ProgressBar from 'react-bootstrap/ProgressBar'
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import Recaptcha from 'react-google-invisible-recaptcha';

const counterbg1 = require('./../../assets/images/background/image-5.jpg');
const map1 = require('./../../assets/images/shape/shape-6.png');
const wellcomebg1 = require('./../../assets/images/resource/image-1.jpg');
const ctabg1 = require('./../../assets/images/background/image-10.jpg');
const sitekey = "6LcfYZwfAAAAAD-3-4ErpWwasUvIrP3yd21s4B2i";



class Index extends Component {

  state = {
    name: '',
    email: '',
    tel: '',
    message: '',
    messageSent: false,
  }

  handleChange = input => e => {
    this.setState({ [input]: e.target.value });
  }

  sendMessage = () => {
    this.Recaptcha.execute();
  }

  onResolved = () => {
    this.setState({ messageSent: true });
  }
    render() {

      return (
    <>
          <Header />

          {/* <!-- Hidden Sidebar --> */}
          {/* <section class="hidden-sidebar ">
              <div class="wrapper-box">
                  <div class="hidden-sidebar-close"><span class="flaticon-cancel"></span></div>
                  <div class="logo"><Link to={'/#'}><img src={require('../../assets/images/sticker_cr_logo.png')} alt="" /></Link></div>
                  <div class="content">
                      <div class="about-widget-two sidebar-widget">
                          <h3>Smart Business Consultancy <br />in New Milton</h3>
                          <div class="text">We denounce with righteous indignation and dislike men who we are to beguiled demoralized by the charms of pleasures that moment, so we blinded desires, that they indignations.</div>
                      </div>
                      

                      <div class="news-widget sidebar-widget">
                          <div class="widget-title">News & Updates</div>
                          <div class="post-wrapper">
                              <div class="image"><Link to="blog-details.html"><img src={require('../../assets/images/resource/news-10.jpg')} alt="" /></Link></div>
                              <div class="category">Business Plans</div>
                              <h4><Link to={'/blog-details'}>How to Manage Business’s <br />Online Reputation</Link></h4>
                          </div>
                          <div class="post-wrapper">
                              <div class="image"><Link to="blog-details.html"><img src={require('../../assets/images/resource/news-11.jpg')} alt="" /></Link></div>
                              <div class="category">Marketing Stratergy</div>
                              <h4><Link to={'/blog-details'}>Inside our Daily Routines as a <br />Good Consultant</Link></h4>
                          </div>
                      </div>
                      
                      <div class="newsletter-widget">
                          <div class="widget-title">Newsletter Subscription</div>
                          <form action="#">
                              <input type="email" placeholder="Enter Email Address" />
                              <button class="theme-btn btn-style-one"><span class="btn-title">Subscribe Us</span></button>
                          </form>
                      </div>
                  </div>
              </div>

          </section> */}


          {/* <!--Search Popup--> */}
          {/* <div id="search-popup" class="search-popup">
              <div class="close-search theme-btn"><span class="flaticon-cancel"></span></div>
              <div class="popup-inner">
                  <div class="overlay-layer"></div>
                  <div class="search-form">
                      <form method="post" action="http://azim.commonsupport.com/Finandox/index.html">
                          <div class="form-group">
                              <fieldset>
                                  <input type="search" class="form-control" name="search-input" value="" placeholder="Search Here" required />
                                  <input type="submit" value="Search Now!" class="theme-btn" />
                              </fieldset>
                          </div>
                      </form>
                      <br />
                      <h3>Recent Search Keywords</h3>
                      <ul class="recent-searches">
                          <li><Link to={'/#'}>Finance</Link></li>
                          <li><Link to={'/#'}>Idea</Link></li>
                          <li><Link to={'/#'}>Service</Link></li>
                          <li><Link to={'/#'}>Growth</Link></li>
                          <li><Link to={'/#'}>Plan</Link></li>
                      </ul>
                  </div>

              </div>
          </div> */}

          {/* <!-- Banner Section --> */}
          <HomeSlider />
          {/* <!--End Banner Section --> */}


          {/* <!-- Welcome Setion --> */}
          <section id="about-us" class="welcome-section pt-0">
              <div class="auto-container">
                  <div class="wrapper-box">
                      <div class="row m-0">
                          {/* <!-- Welcome Block One --> */}
                          <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                              <div class="inner-box">
                                  <div class="icon-box"><span class="flaticon-analysis"></span></div>
                                  <h2>Representação</h2>
                                  <div class="text">
                                  Somos uma representação de insumos agrícolas e fertilizantes foliares, atuante no segmento de compra e venda de defensivos químicos. Negociamos com Canais Autorizados, Revendas Agrícolas e Cooperativas em todo o país. Atendemos marcas como Syngenta, Bayer, BASF, FMC, Corteva, Ouro Fino, UPL entre outras.
                                  </div>
                                  {/* <div class="read-more"><Link to={'/#'}>Read More</Link></div> */}
                              </div>
                          </div>
                          {/* <!-- Welcome Block One --> */}
                          <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                              <div class="inner-box" style={{ backgroundImage: "url(" + wellcomebg1 + ")" }}>
                                  <div class="icon-box"><span class="flaticon-tax"></span></div>
                                  <h2>Negociação</h2>
                                  <div class="text">
                                    Nossa equipe está capacitada para atendê-lo tanto na compra ou na venda, do início ao fim da negociação, trazendo tranquilidade com confiança. Portanto:
                                    <ul>
                                      <li>- Fornecedor solicite contato de nosso representante para análise e auxílio na venda de seu estoque.</li>
                                      <li>- Cliente, nos informe sua necessidade que encontraremos um fornecedor e condições para melhor atendê-lo.</li>
                                    </ul>
                                  </div>
                                  {/* <div class="read-more"><Link to={'/#'}>Read More</Link></div> */}
                              </div>
                          </div>
                          {/* <!-- Welcome Block One --> */}
                          <div class="welcome-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                              <div class="inner-box">
                                  <div class="icon-box"><span class="flaticon-work-1"></span></div>
                                  <h2>Pós-Venda</h2>
                                  <div class="text">
                                  A partir do fechamento da COMPRA, nossa equipe irá cuidar de todos os detalhes, desde a coleta, até a entrega e os devidos recebimentos, você só irá se preocupar em receber a mercadoria. E fornecedor, após a emissão da nota, é só aguardar receber que o resto é conosco.
                                  </div>
                                  {/* <div class="read-more"><Link to={'/#'}>Read More</Link></div> */}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

            {/* <!-- Funfacts Section --> */}
            <section class="funfacts-section" style={{ backgroundImage: "url(" + counterbg1 + ")" }}>
              <div class="auto-container">
                  {/* <!-- Fact Counter --> */}
                  <div class="fact-counter-two">
                      <div class="row">
                          {/* <!--Column--> */}
                          <div class="column counter-column col-xl-3 col-lg-6">
                              <div class="inner wow fadeInLeft" data-wow-delay="0ms" data-wow-duration="1500ms">
                                  <div class="content">
                                      <div class="icon"><img src={require('../../assets/images/icons/icon-12.png')} alt="" /></div>
                                      <div class="count-outer count-box">
                                          <CountUp
                                              end={2000}
                                              redraw={true}
                                          >
                                              {({ countUpRef, start }) => (
                                                  <VisibilitySensor
                                                      onChange={start}
                                                      delayedCall
                                                  >
                                                      <span class="count-text" ref={countUpRef} />
                                                  </VisibilitySensor>
                                              )}
                                          </CountUp>
                                          {/* <span>%</span> */}
                                      </div>
                                      <div class="counter-title">Clientes Prospectados</div>
                                  </div>
                              </div>
                          </div>

                          {/* <!--Column--> */}
                          <div class="column counter-column col-xl-3 col-lg-6">
                              <div class="inner wow fadeInLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                                  <div class="content">
                                      <div class="icon"><img src={require('../../assets/images/icons/icon-13.png')} alt="" /></div>
                                      <div class="count-outer count-box">
                                          <CountUp
                                              end={450}
                                              // redraw={true}
                                          >
                                              {({ countUpRef, start }) => (
                                                  <VisibilitySensor
                                                      onChange={start}
                                                      delayedCall
                                                  >
                                                      <span class="count-text" ref={countUpRef} />
                                                  </VisibilitySensor>
                                              )}
                                          </CountUp>
                                          {/* <span>%</span> */}
                                      </div>
                                      <div class="counter-title">Clientes Ativos</div>
                                  </div>
                              </div>
                          </div>

                          {/* <!--Column--> */}
                          <div class="column counter-column col-xl-3 col-lg-6">
                              <div class="inner wow fadeInLeft" data-wow-delay="600ms" data-wow-duration="1500ms">
                                  <div class="content">
                                      <div class="icon"><img src={require('../../assets/images/icons/icon-14.png')} alt="" /></div>
                                      <div class="count-outer count-box">
                                      <span>R$ </span>
                                          <CountUp
                                              end={25}
                                              redraw={true}
                                          >
                                              {({ countUpRef, start }) => (
                                                  <VisibilitySensor
                                                      onChange={start}
                                                      delayedCall
                                                  >
                                                      <span class="count-text" ref={countUpRef} />
                                                  </VisibilitySensor>
                                              )}
                                          </CountUp>
                                          <span>mi</span>
                                      </div>
                                      <div class="counter-title">Valor Negociado</div>
                                  </div>
                              </div>
                          </div>

                          {/* <!--Column--> */}
                          <div class="column counter-column col-xl-3 col-lg-6">
                              <div class="inner wow fadeInLeft" data-wow-delay="900ms" data-wow-duration="1500ms">
                                  <div class="content">
                                      <div class="icon"><img src={require('../../assets/images/icons/icon-15.png')} alt="" /></div>
                                      <div class="count-outer count-box">
                                          <CountUp
                                              start={100}
                                              end={0}
                                              redraw={true}
                                          >
                                              {({ countUpRef, start }) => (
                                                  <VisibilitySensor
                                                      onChange={start}
                                                      delayedCall
                                                  >
                                                      <span class="count-text" ref={countUpRef} />
                                                  </VisibilitySensor>
                                              )}
                                          </CountUp>
                                          <span>%</span>
                                      </div>
                                      <div class="counter-title">Inadimplência</div>
                                  </div>
                              </div>
                          </div>

                      </div>
                  </div>
              </div>
          </section>

          {/* <!-- Services Section Three --> */}
          {/* <section class="services-section-three">
              <div class="auto-container">
                  <div class="sec-title text-center">
                      <div class="sub-title">Services</div>
                      <h2>What We Do</h2>
                  </div>
                  <div class="row">
                      
                      <div class="service-block-one col-lg-4">
                          <div class="inner-box">
                              <div class="icon"><span class="flaticon-assets"></span><span class="round-shape"></span></div>
                              <h3>Business/Family Loan</h3>
                              <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                          </div>
                      </div>
                      
                      <div class="service-block-one col-lg-4">
                          <div class="inner-box">
                              <div class="icon"><span class="flaticon-life-insurance"></span><span class="round-shape"></span></div>
                              <h3>Life/Car Insurance</h3>
                              <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                          </div>
                      </div>
                      
                      <div class="service-block-one col-lg-4">
                          <div class="inner-box">
                              <div class="icon"><span class="flaticon-consulting"></span><span class="round-shape"></span></div>
                              <h3>Business Consultancy</h3>
                              <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod tempor incididunt ut labore.</div>
                          </div>
                      </div>
                  </div>
              </div>
          </section> */}

          {/* <!-- Case Studies --> */}
          {/* <section id="products"class="cases-section">


              <div class="case-tabs">
                  <div class="container-fluid">
                      <div class="sec-title text-center">
                          <div class="sub-title">Produtos</div>
                          <h2>Conheça nossa linha de Fertilizantes Foliares</h2>
                      </div>
                      <div class="case-tab-wrapper">
                          
                          <div class="case-tabs-content">
                          
                              <div class="case-tab active-tab">
                                  <CaseStudy />
                              </div>
                          </div>
                      </div>
                  </div>

              </div>
          </section> */}

          {/* <!-- Testimonial Section --> */}
          {/* <Testimonial2 /> */}

          {/* <!-- Gallery Section --> */}
          {/* <PortfolioFilter1 /> */}

          {/* <!-- Map Section --> */}
          {/* <section class="map-section">
              <div class="map-column">
                  <GoogleMaps
                      apiKey={"AIzaSyCK3I31_cWq_ioDT2wb2VvZ00tVRO6B2LM"}
                      style={{ height: "800px", width: "100%" }}
                      zoom={15}
                      center={{ lat: -20.444139204785834, lng: -54.61798352439648 }}
                      markers={{ lat: -20.444139204785834, lng: -54.61798352439648 }} //optional
                      //-20.444139204785834, -54.61798352439648
                  />
              </div>
          </section> */}



          {/* <!-- Contact Section Two --> */}
          {/* <ContactForm /> */}

          {/* <!--End Contact Section Two --> */}



          {/* <!-- Feature Section Two --> */}
          {/* <Featureslide /> */}

          {/* <!-- News Section --> */}
          {/* <section class="news-section">
              <div class="auto-container">
                  <div class="sec-title text-center">
                      <div class="sub-title">News</div>
                      <h2>News From Resource</h2>
                  </div>
                  <div class="row">
                      
                      <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                          <div class="inner-box">
                              <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-1.jpg')} alt="" /></Link></div>
                              <div class="lower-content">
                                  <div class="category">Business</div>
                                  <ul class="post-meta">
                                      <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>24th March 2020</Link></li>
                                      <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                  </ul>
                                  <h3><Link to={'/blog-details'}>UX is best solution for your <br />business & make noise.</Link></h3>
                                  <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                              </div>
                          </div>
                      </div>
                      
                      <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                          <div class="inner-box">
                              <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-2.jpg')} alt="" /></Link></div>
                              <div class="lower-content">
                                  <div class="category">Business</div>
                                  <ul class="post-meta">
                                      <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>26th March 2020</Link></li>
                                      <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                  </ul>
                                  <h3><Link to={'/blog-details'}>Business consultant is involved in the planning.</Link></h3>
                                  <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                              </div>
                          </div>
                      </div>

                      <div class="news-block-one col-lg-4 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1200ms">
                          <div class="inner-box">
                              <div class="image"><Link to={'/#'}><img src={require('../../assets/images/resource/news-3.jpg')} alt="" /></Link></div>
                              <div class="lower-content">
                                  <div class="category">Business</div>
                                  <ul class="post-meta">
                                      <li><Link to={'/#'}><i class="far fa-calendar-alt"></i>28th March 2020</Link></li>
                                      <li><Link to={'/#'}><i class="far fa-user"></i>By Admin</Link></li>
                                  </ul>
                                  <h3><Link to={'/blog-details'}>Business consultant is a professional who analyzes.</Link></h3>
                                  <div class="text">Lorem ipsum dolor sit amet, consectet ur adipisicing elit, sed do eiusmod temp or incididunt ut labore et dolore</div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section> */}

          {/* <!-- CTA Section --> */}
          {/* <section class="cta-section">
              <div class="auto-container">
                  <div class="wrapper-box" style={{ backgroundImage: "url(" + ctabg1 + ")" }}>
                      <div class="row align-items-center">
                          <div class="col-lg-6">
                              <div class="sec-title light">
                                  <div class="sub-title">Call To Action</div>
                                  <h2>We Waiting For <                                            </a>
                          <div class="col-lg-6">
                              <form action="#">
                                  <input type="email" placeholder="Enter your email address" />
                                  <button class="theme-btn btn-style-one" type="submit" name="submit-form"><span class="btn-title"><i class="fal fa-envelope"></i>Subscribe Now</span></button>
                              </form>
                          </div>
                      </div>
                  </div>
              </div>
          </section> */}

          <Recaptcha 
            ref = {this.recaptchaRef }
            sitekey="6LcfYZwfAAAAAD-3-4ErpWwasUvIrP3yd21s4B2i"
            onResolved={this.onResolved}
          />

          <Footer />
          
      </>
    )
  }
}

export default Index;
